<template>
   <div>
    <v-banner single-line class="banner">
       <v-img
           src="https://h5.ophyer.cn/official_website/banner/num-banner-h5-newRetail-01.png"
           height="100%"
           :transition="false"
           class="d-flex align-center"
       >
         <template v-slot:placeholder>
           <v-row class="fill-height ma-0" align="center" justify="center">
             <v-progress-circular
                 indeterminate
                 size="80"
                 color="red lighten-5"
             ></v-progress-circular>
           </v-row>
         </template>
          <v-container color="transparent" class="d-flex  max-width-1200">
           <v-card color="transparent" flat >
             <div class="d-flex flex-no-wrap justify-space-between ml-4">
               <div>
                 <v-card-title class="white--text h5-banner-title font-size-h5banner-title" >
                   VR云店
                 </v-card-title>
               </div>
             </div>
           </v-card>
         </v-container>
       </v-img>
     </v-banner>

    <!-- 功能 -->
    <v-card flat class="py-6 position-r">
      <v-card-title class="justify-center  font-size-20 color-#333333 pa-0 line-height-28">功能</v-card-title>
      <v-tabs v-model="tab" background-color="transparent" class="xs-tab" >
        <v-tab v-for="(v, k) in list" :key="k" class="pa-0"
               :class="[k === 0 ? 'ml-5':'ml-6',k === list.length - 1 ? 'mr-5':'']"
               style="min-width: unset">
          <div class="font-size-16 letter-spacing">{{ v.title }}</div>
        </v-tab>
      </v-tabs>
      <v-tabs-items class="mt-3 mx-5" v-model="tab" style="border-radius: 10px;box-shadow: 0px 0px 18px 0px rgba(0, 92, 193, 0.16);">
        <v-tab-item v-for="(v, k) in list" :key="k" :transition="false">
          <v-card  color="white" class="pa-5">
            <v-img :src="v.img" width="100%" min-height="200" contain></v-img>
            <v-card-title class="pa-0 mt-4 font-size-18 ">{{v.h2}}</v-card-title>
            <v-card-text class="pa-0 mt-4 font-size-16 " v-text="v.sub" />
            <v-card-text class="pa-0 mt-2 font-size-13 color-666" v-text="v.content" v-html="v.content"/>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <div class="tab-btn-prev" @click.stop="handleTabBtn('prev')"></div>
      <div class="tab-btn-next" @click.stop="handleTabBtn('next')"></div>
    </v-card>

    <!-- 方案优势 -->
    <v-card class="px-4 py-6" flat color="#F5F8FC" >
      <v-card-title class="justify-center  font-size-20 pa-0 line-height-28">方案优势</v-card-title>
      <div class="mt-7">
        <v-row v-for="(row,rowIndex) in Vorteil" :key="rowIndex">
          <v-card class="pa-3 d-flex h5-card mb-3" min-height="90" width="100%" flat>
            <v-img :src="row.img" max-width="65" contain position="center"></v-img>
            <div class="ml-4">
              <v-card-title class="pa-0 mt-3 font-size-16  line-height-20">
                {{row.title}}
              </v-card-title>
              <v-card-text class="pa-0 mt-2 font-size-13 color-666 line-height-20">
                {{row.text}}
              </v-card-text>
            </div>
          </v-card>
        </v-row>
      </div>
    </v-card>
    <!-- 精彩案列 -->
    <v-card class="warps warp4 pt-2 pb-5" >
      <v-card-title class="justify-center  font-size-20 color-333 pa-0 mt-6 mb-6 line-height-28">案例</v-card-title>
      <v-row class="pa-0 px-2" style="margin:0 auto;width:100%;">
          <v-col v-for="(info,infoIndex) in anli" :key="infoIndex" :cols='6' class="d-flex justify-center" @click="goCaseDetail(info)">
             <v-card flat class="h5-card item-card" width="100%"  style="border-radius:5px;">
                <v-img max-width="100%" contain :src="info.coverImg"></v-img>
                <div class="text-box px-3">
                    <v-card-text class="sizeStyle pa-0 mt-3 mb-1 title" style="font-size: 12px !important;" v-text="info.itemTitle"></v-card-text>
                    <!-- <v-card-text class="sizeStyle pa-0 tip pb-7" v-text="info.itemTip"></v-card-text> -->
                </div>
              </v-card>
          </v-col>
      </v-row>
    </v-card>
   </div>
</template>

<script>
import textData from '@/static/textData.js' // 合作案例
//import qs from 'qs'
export default {
  name: "OnlineRetailers",
  data(){
    return{
      model: 0,
      bannerLoad: false,
      imgLoad: false,
      windowHight: window.innerHeight/2,
      tab:null,
      list:[
        {title:"VR全景",h2:'VR展厅',sub:'沉浸式交互体验，全面展示品牌信息',img:'https://h5.ophyer.cn/official_website/other/xinshou1-1.png',content:'• 720°可交互全景，为您量身打造线上虚拟展厅<br/>• 全景编辑制作平台，简单操作即可生成场景'},
        {title:"360环物",h2:'360环物',sub:'贴合电商场景，让产品“动”起来',img:'https://h5.ophyer.cn/official_website/other/xinshou1-2.png',content:'• 简单拍摄即可生成360环物<br/>• 打破传统的静态图片展示，更多细节展示'},
        {title:"商品热点",h2:'商品热点',sub:'点击即可查看商品详情',img:'https://h5.ophyer.cn/official_website/other/xinshou1-3.png',content:'• 支持添加多张图片轮播展<br/>• 可添加多个产品规格<br/>• 可以添加商品列表展示同系列多个产品'},
        {title:"优惠券热点",h2:'优惠券热点',sub:'快速发放优惠券 助力销售转化',img:'https://h5.ophyer.cn/official_website/other/xinshou1-4.png',content:'• 自定义优惠券内容<br/>• 在场景内即可核销'},
      ],
      Vorteil:[
        {
          img:  require("../../../public/img/icons/DigitalMuseum/newretail1.png"),
          title: '打通各大电商平台',
          text: '一键卖货 全网引流'
        },
        {
          img: require("../../../public/img/icons/DigitalMuseum/newretail2.png"),
          title: '多场景自由切换',
          text: '支持添加多个场景店铺再大也能装下'
        },
        {
          img: require("../../../public/img/icons/DigitalMuseum/newretail3.png"),
          title: '智慧营销',
          text: '营销组件 助力智慧营销'
        },
        {
          img: require("../../../public/img/icons/DigitalMuseum/newretail4.png"),
          title: '二次开发',
          text: '可以导出离线包自定义开发'
        }
     ],
     anli:[textData.anli.sanxingshoujiquanjingdian,textData.anli.kidsyundian,textData.anli.xingyehongbaoyu,textData.anli.wanshifujiaju],
    }
  },
  mounted(){
    this.bannerLoad = true;
  },
  methods: {
    handleLoad (){
      this.imgLoad = true;
    },
    goCaseDetail(item) {
      console.log(item,'item')
      this.$router.push({ 
        name: 'h5CaseDetail',
        query: {
          source: 'qita',
          anli: item.self
        }
      })
    },
    handleTabBtn: function (type){
      const maxTab = this.list.length - 1;
      if(type === "prev"){
        if(this.tab === 0){
          this.tab = maxTab;
        }else {
          this.tab--;
        }
      }else if(type === "next"){
        if(this.tab === maxTab){
          this.tab = 0;
        }else {
          this.tab++;
        }
      }
    }
  }
}
</script>

<style scoped lang='scss'>

.banner{
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
}
::v-deep .h5-banner-title{
  margin-top: 31px !important;
}
.public-title{
  height: 0;
  margin: 65px auto 65px;
}
.banner_title{
  font-size: 60px;
  //font-family:$bold-font-family;
  color: #FFFFFF !important;
  // padding-top: 21%;
}
.scence_text{
  color: #FFFFFF !important;
  line-height: 26px !important;
}
.banner_span{
  //font-family: $normal-font-family;
  color: #2672FF !important;
  font-size: 20px;
}
::v-deep .v-banner__wrapper{
  padding: 0 !important;
}
.first_card{
  margin-top: 80px;
}
.containerStyle{
  margin-top: 80px;
  .v-card__title{
    line-height: 26px !important;
  }
  .borderimg{
    width:600px;
    height:300px;
    img{
      border-radius: 10px;
      float: right;
    }
  }
  .borderimg_two{
    width:600px;
    height:300px;
    img{
      border-radius: 10px;
      float: left;
    }
  }
  .part-one{
    margin-bottom: 21px;
    .text-wrap{
      width: 600px;
      .con_tit{
        font-size: 24px;
        font-weight: bold;
        color: #333333;
        line-height: 30px;
        margin-top:84px
      }
      .con_text{
        height: 40px;
        font-size: 14px;
        font-weight: 400;
        color: #666;
        line-height: 26px;
      }
      .banner-btn {
        margin-top: 50px;
        width: 160px;
        height: 50px;
        background-color: #0568FD;
        border-radius: 25px;
        font-size: 20px !important;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 50px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
.titleh1{
  font-size: 80px;
  //font-family: $bold-font-family;
  line-height: 26px !important;
}
.spantitle{
  font-size: 24px;
  //font-family: $body-font-family;
  line-height: 26px !important;
}
.suntitle{
  font-size: 30px;
  //font-family: $bold-font-family;
  // font-weight: bold;
  font-style: italic;
  line-height: 48px !important;
}
.ptitle{
  font-size: 14px;
  font-family: $body-font-family;
  // font-weight: 400;
  line-height: 26px !important;
}

::v-deep .v-carousel__controls{
  display: none !important;
}
.item-card {
  margin-bottom: 15px;
  padding: 10px;
  box-shadow: 0px 10px 20px 0px rgba(0, 60, 255, 0.1);
  border-radius: 8px;
  &::after {
      content: "";
      background-image: url("../../assets/images/home/case-icon.png");
      background-size: 100%;
      width: 20px;
      height: 20px;
      position: absolute;
      bottom: 16px;
      right: 10px;
  }
  transition: 0.3s;
  &:hover {
      transform: scale(1.04);
      box-shadow: 0px 10px 20px 0px rgba(0, 60, 255, 0.1) !important;
  }
  &:focus{
     display: none;
  }
}
.wap_case{
  .public-title{
    margin: 45px auto 65px !important;
  }
}
.warp {
  margin: 83px auto 0;
  text-align: center;
}
.warp1 {
  background: #FBFBFB;
  padding: 1px 0 40px;
  margin-top: 40px;
  .con {
    width: 1080px;
    margin: 20px auto 0;
  }
  .box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: center;
    img {
      width: 120px;
      height: 120px;
    }
  }
  .tip {
    width: 266px;
    opacity: 0.6;
  }
}
.warp2 {
  .box {
    width: 1024px;
    margin: 50px auto 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .left {
    margin-right: 55px;
    img {
      width: 400px;
      height: 260px;
      border-radius: 30px;
      object-fit: cover
    }
  }
  .right {
    text-align: start;
    //font-family: SourceHanSansCN-Regular;
    padding-top: 30px;
    .v-card__text {
      padding: 0;
    }
    .title {
      font-family: Source Han Sans CN;
      font-size: 24px !important;
      font-weight: 600 !important;
    }
    .sub{
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #000000;
      line-height: 18px;
      margin-top: 16px;
    }
    .line{
      width: 68px;
      height: 4px;
      background: #000000;
      margin-top: 5px;
    }
    .tip {
      margin: 10px 0;
      font-size: 16px !important;
      color: rgb(102, 102, 102) !important;
    }
    .text {
      font-size: 14px;
      line-height: 26px;
    }
  }
}
</style>